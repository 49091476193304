import { default as indexqbhES7kGxNMeta } from "/codebuild/output/src3921127278/src/Conversational-bot/client/pages/index.vue?macro=true";
import { default as speech_45to_45textrnKqBifrFbMeta } from "/codebuild/output/src3921127278/src/Conversational-bot/client/pages/speech-to-text.vue?macro=true";
import { default as text_45to_45speechYOeu6ENu2FMeta } from "/codebuild/output/src3921127278/src/Conversational-bot/client/pages/text-to-speech.vue?macro=true";
export default [
  {
    name: indexqbhES7kGxNMeta?.name ?? "index",
    path: indexqbhES7kGxNMeta?.path ?? "/",
    meta: indexqbhES7kGxNMeta || {},
    alias: indexqbhES7kGxNMeta?.alias || [],
    redirect: indexqbhES7kGxNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3921127278/src/Conversational-bot/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: speech_45to_45textrnKqBifrFbMeta?.name ?? "speech-to-text",
    path: speech_45to_45textrnKqBifrFbMeta?.path ?? "/speech-to-text",
    meta: speech_45to_45textrnKqBifrFbMeta || {},
    alias: speech_45to_45textrnKqBifrFbMeta?.alias || [],
    redirect: speech_45to_45textrnKqBifrFbMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3921127278/src/Conversational-bot/client/pages/speech-to-text.vue").then(m => m.default || m)
  },
  {
    name: text_45to_45speechYOeu6ENu2FMeta?.name ?? "text-to-speech",
    path: text_45to_45speechYOeu6ENu2FMeta?.path ?? "/text-to-speech",
    meta: text_45to_45speechYOeu6ENu2FMeta || {},
    alias: text_45to_45speechYOeu6ENu2FMeta?.alias || [],
    redirect: text_45to_45speechYOeu6ENu2FMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3921127278/src/Conversational-bot/client/pages/text-to-speech.vue").then(m => m.default || m)
  }
]